import Vue from 'vue'
import { BootstrapVue, ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import FormWizard from 'vue-form-wizard'
import VueAutosuggest from 'vue-autosuggest'
import VueCompositionAPI from '@vue/composition-api'
import VueHtmlToPaper from 'vue-html-to-paper'
// import Axios from 'axios'
// import { setupCache } from 'axios-cache-interceptor';
import _ from 'lodash'
import Speech from 'speak-tts'
import { createPinia, PiniaVuePlugin } from 'pinia';
import '@/registerServiceWorker'
import RouterTab from 'vue-router-tab'
import VueGoodTablePlugin from 'vue-good-table'
import moment from 'moment'
import VueMoment from 'vue-moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { VSelectTree, VTree } from 'vue-tree-halower'
import VueTreeList from 'vue-tree-list'
import VueTheMask from 'vue-the-mask'
import VueFullscreen from 'vue-fullscreen'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import 'highlight.js/styles/github-gist.css'
import InputDateTime from '@/components/InputDateTime.vue'
// import { RayPlugin } from 'vue-ray/vue2'
import { Icon } from '@iconify/vue2'

/* import i18n from '@/libs/i18n' */
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { image, mimes, size, min, max, max_value, min_value, numeric } from 'vee-validate/dist/rules'
import { initDb } from '@core/idb/indexDb'
import VueHotkey from 'v-hotkey'
import router from './router'
import store from './store'
import App from './App.vue'

// Debug
// Global Components
import './global-components'
// 3rd party plugins
import axios from '@/libs/axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import useJwt from './@core/auth/jwt/useJwt'
import { initialAbility } from './libs/acl/config'
import sLocalStorage from './@core/utils/secure-storage'

import hljs from 'highlight.js/lib/core';
import hljsJson from 'highlight.js/lib/languages/json';

import preloadAudio from './preloadAudio'
import { useAuthStore } from './pinia/auth'

preloadAudio()
extend('image', image)
extend('size', size)
extend('mimes', mimes)
extend('min', min)
extend('max', max)
extend('max_value', max_value)
extend('min_value', min_value)
extend('numeric', numeric)

initDb()
require('moment/locale/id')
moment.locale('id')

const optionPrint = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
}

hljs.registerLanguage('json', hljsJson);

// BSV Plugin Registration
Vue.component('InputDateTime', InputDateTime)
Vue.use(ToastPlugin)
Vue.use(VueHtmlToPaper, optionPrint)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(RouterTab)
Vue.use(VueGoodTablePlugin)
Vue.use(FormWizard)
Vue.use(VueAutosuggest)
Vue.use(ToastificationContent)
Vue.use(VueFullscreen)
// Vue.use(VSelectTree)
// Vue.use(VTree)
Vue.use(VueHotkey)
Vue.use(VueTreeList)
Vue.use(VueTheMask)
Vue.use(PiniaVuePlugin)
Vue.use(hljs.vuePlugin);

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('Icon', Icon)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('@core/assets/fonts/font-awesome-5/css/all.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@core/scss/vue/libs/vue-select.scss')

Vue.config.productionTip = false

Vue.use(VueMoment, { moment })

// Vue.use(RayPlugin, { interceptErrors: false, host: '127.0.0.1', port: 23517 })

Vue.filter('formatDate', (value) => {
  return value ? moment(String(value)).format('DD/MM/YYYY') : ''
})

Vue.filter('formatDateTime', (value) => {
  return value ? moment(String(value)).format('DD/MM/YYYY hh:mm') : ''
})

// axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.prototype.$axios = axios
window.axios = axios
global.axios = axios

try {
  const speech = new Speech()
  const lang = 'id-ID'
  if (speech.hasBrowserSupport()) {
    speech
      .init({
        lang,
        rate: 1,
        pitch: 1,
        volume: 1
      })
      .then((data) => {
        const { voices } = data
        const voice = voices.filter((v) => v.lang === lang)
        if (voice[0]) speech.setVoice(voice[0].name)
        else console.error(`Voice not found (lang: ${lang})`)
        Vue.prototype.$speechVoices = voice.map((v) => v.name)
      })
    Vue.prototype.$speech = speech
  }
} catch (error) {
  console.error(
    'Browser tidak mendukung fitur text to speech, fitur terkait akan di nonaktifkan'
  )
}

const globalHelper = {
  install(Vue, options) {
    Vue.prototype.arrayRuanganToLists = (ruangans) => {
      if (ruangans.length > 0) {
        return ruangans.map((ruangan) => {
          return {
            id: ruangan.id,
            name: ruangan.deskripsi
          }
        })
      }

      return []
    }

    Vue.prototype.arrayToLists = (arrays, customField = false) => {
      if (arrays.length > 0) {
        return arrays.map((data) => {
          let { name } = data

          if (customField && customField.length > 0) {
            switch (customField.length) {
              case 1:
                name = data[customField[0]]
                break
              case 2:
                name = data[customField[0]][customField[1]]
                break
              case 3:
                name = data[customField[0]][customField[1]][customField[2]]
                break
              case 4:
                name =
                  data[customField[0]][customField[1]][customField[2]][
                    customField[3]
                  ]
                break
              default:
                name = null
                break
            }
          }
          return {
            id: data.id,
            name
          }
        })
      }

      return []
    }

    Vue.prototype.toast = (type, message) => {
      this.$bvToast.toast(message.message || null, {
        title: message.title || null,
        variant: type,
        solid: false
      })
    }

    Vue.prototype.$nikHandler = async (nik) => {
      if (nik.length === 16) {
        const d = Vue.prototype.dateFromNik(nik)
        const date = moment(d.date).format('DD/MM/YYYY')
        const jk = Vue.prototype.getJkFromNik(d)
        const provinsi = Vue.prototype.provinsiFromNik(nik)
        const kota = await Vue.prototype.kotaFromNik(nik)
        const kecamatan = await Vue.prototype.kecamatanFromNik(nik)

        return {
          date,
          jk,
          provinsi,
          kota,
          kecamatan
        }
      }
    }

    Vue.prototype.dateFromNik = (nik) => {
      if (typeof nik === 'number') {
        nik = `${nik}`
      }

      const dateString = nik.substr(6, 6)

      let day = dateString.substr(0, 2)
      const month = dateString.substr(2, 2)
      let year = dateString.substr(4, 2)

      if (day > 40) {
        day -= 40
      }

      if (parseInt(`20${year}`, 10) > new Date().getFullYear()) {
        year = `19${year}`
      } else {
        year = `20${year}`
      }

      return {
        date: new Date(`${year}-${month}-${day}`),
        d: dateString.substr(0, 2),
        m: month,
        y: year
      }
    }

    Vue.prototype.getJkFromNik = (parsedDate) => {
      if (parsedDate.d > 40) {
        return (
          store.getters['masterdata/jk'].find((jk) => jk.value == 2) || null
        )
      }

      return store.getters['masterdata/jk'].find((jk) => jk.value == 1) || null
    }

    Vue.prototype.provinsiFromNik = (nik) => {
      const code = nik.substr(0, 2)

      const provinsi = store.getters['masterdata/provinsi'].find(
        (p) => p.value == code
      )

      return provinsi
    }

    Vue.prototype.kotaFromNik = async (nik) => {
      const code = nik.substr(0, 4)

      const { data } = await axios.get(`wilayah/${code}?jenis=2`)

      if (data.success && data.data) {
        return data.data
      }

      return null
    }

    Vue.prototype.kecamatanFromNik = async (nik) => {
      const code = nik.substr(0, 6)

      const { data } = await axios.get(`wilayah/${code}?jenis=3`)

      if (data.success && data.data) {
        return data.data
      }

      return null
    }

    Vue.prototype.failSafe = (variable, fallback = '') => {
      if (variable === undefined || variable === null) {
        return fallback
      }
      return variable
    }
  }
}

Vue.use(globalHelper)

const pinia = createPinia();
const authStore = useAuthStore(pinia)

authStore.setToken({ access_token: authStore.token, refreshToken: authStore.refreshToken })

const vueIns = new Vue({
  store,
  pinia,
  router,
  /* i18n, */
  render: (h) => h(App)
}).$mount('#app')

// const interceptUnauthorized = axios.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (err) => {
//     if (_.get(err.response, 'status') === 401) {
//       axios.interceptors.response.eject(interceptUnauthorized) // ejecting the interceptor to avoid looping calls.
//       localStorage.removeItem(useJwt.jwtConfig?.storageTokenKeyName)
//       localStorage.removeItem(useJwt.jwtConfig?.storageRefreshTokenKeyName)
//       sLocalStorage.removeItem('userData')
//       vueIns.$ability.update(initialAbility)
//       if (vueIns.$route.name !== 'auth-login') {
//         vueIns.$router.push({ name: 'auth-login' })
//       }
//       // window.location.reload()
//     }
//     return Promise.reject(err)
//   }
// )
