<template>
  <div
    :id="modalId"
    class="hidden pos"
    style="font-family: monospace !important;"
  >
    <div class="row">
      <div class="col my-auto">
        <table>
          <!-- START HEADER -->
          <tr>
            <td colspan="2" class="mb-3 text-center">
              <b-img
                :src="entity.logo"
                alt="Logo Faskes"
                style="max-width: 10rem; max-height: 3rem;"
              />
            </td>
          </tr>
          <tr>
            <td class="text-center" colspan="2">
              <strong>{{
                entity.name
              }}</strong>
            </td>
          </tr>
          <tr>
            <td class="text-center" colspan="2">
              {{ entity.address }}
            </td>
          </tr>
          <tr>
            <td class="text-center" colspan="2">
              {{ entity.phone }}
            </td>
          </tr>
          <!-- END HEADER -->
          <!-- START TITLE -->
          <tr style="border-top: 2px solid">
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2" class="text-center">
              <strong>REGISTRASI PASIEN</strong>
            </td>
          </tr>
          <!-- END TITLE -->
          <tr>
            <td>No. Daftar</td>
            <td>: {{ noPendaftaran }}</td>
          </tr>
          <tr>
            <td>Tgl Daftar</td>
            <td>: {{ tglPendaftaran }}</td>
          </tr>
          <tr>
            <td>No. RM</td>
            <td>: {{ noRm }}</td>`
          </tr>
          <tr>
            <td>Nama Pasien</td>
            <td>: {{ namaPasien }}</td>
          </tr>
          <tr>
            <td>Tgl. Lahir/Umur</td>
            <td>: {{ tglLahir }}</td>
          </tr>
          <tr>
            <td>Status Pasien</td>
            <td>: {{ statusPasien }}</td>
          </tr>
          <tr>
            <td>Cara Bayar</td>
            <td>: {{ penjamin }}</td>
          </tr>
          <tr v-if="noPenjamin">
            <td>No. Kartu Jaminan </td>
            <td>: {{ noPenjamin }}</td>
          </tr>

          <tr>
            <td colspan="2" class="text-center">
              <strong>TUJUAN PASIEN</strong>
            </td>
          </tr>
          <tr>
            <td>Unit Pelayanan</td>
            <td>: {{ unit }}</td>
          </tr>
          <tr>
            <td>Dokter</td>
            <td>: {{ dokter }} asd</td>
          </tr>
          <tr>
            <td colspan="2" class="text-center">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table>
                <tr>
                  <td>Ceklis</td>
                  <td>Diskon Rp.</td>
                  <td>&nbsp;</td>
                </tr>
                <tr v-for="(value, index) in withBoxes" :key="index">
                  <td>
                    <div style="border: 2px solid #000; min-width: 3em">
                      &nbsp;
                    </div>
                  </td>
                  <td>
                    <div style="border: 2px solid #000; min-width: 5em">
                      &nbsp;
                    </div>
                  </td>
                  <td>
                    {{ value }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td class="text-center">
              <div style="margin-top: 5em;">Petugas Pendaftaran</div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colspan="3" class="text-center">
              {{ pegawai }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'

export default {
  inject: ['entity'],
  props: {
    modalId: {
      type: String,
      default: 'buktiPendaftaran'
    },
    pasien: {
      type: Object,
      default: () => ({})
    },
    pendaftaran: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      userData: getUserData()
    }
  },
  computed: {
    noPendaftaran() {
      return this.pendaftaran.nomor_pendaftaran
    },
    tglPendaftaran() {
      const tgl = this.pendaftaran.tanggal_pendaftaran
      return tgl ? this.$moment(tgl).format('DD-MM-YYYY') : ''
    },
    noRm() {
      return this.pasien.norm
    },
    namaPasien() {
      return this.pasien.nama
    },
    tglLahir() {
      const tgl = this.pasien.tanggal_lahir
      if (!tgl) return ''
      const m = this.$moment(tgl)
      return `${m.format('DD-MM-YYYY')} (${m.fromNow(true)})`
    },
    statusPasien() {
      return this.pasien?.referensi_status_pasien?.deskripsi || ''
    },
    penjamin() {
      return this.pendaftaran?.penjamin?.jenis?.deskripsi || ''
    },
    noPenjamin() {
      return this.pendaftaran?.penjamin?.nomor_penjamin
    },
    unit() {
      return this.pendaftaran?.tujuan_pasien?.unit?.deskripsi || ''
    },
    dokter() {
      const { pegawai=null } = this.pendaftaran?.tujuan_pasien?.dokter || {}
      if (!pegawai) return ''
      let nama = pegawai?.gelar_depan ? `${pegawai.gelar_depan  }. ` : ''
      nama += `${pegawai.nama  }` || ''
      nama += pegawai?.gelar_belakang ? `, ${pegawai.gelar_belakang}` : ''
      return nama.trim()
    },
    withBoxes() {
      return [
        'Pemeriksaan (Anamnesa)',
        'Laboratorium',
        'Radiologi / USG / Rontgen',
        'EKG',
        'Spirometri',
        'Resep',
        'Injeksi Intra Artikuler / Ateritis',
        'Ganti Verban (GV)',
        'Konsul: .....................',
        '.............................'
      ]
    },
    pegawai() {
      const { pegawai=null } = this.pendaftaran || {}
      if (!pegawai) return 'Admin'
      let nama = pegawai?.gelar_depan ? `${pegawai.gelar_depan  }. ` : ''
      nama += `${pegawai.nama  }` || ''
      nama += pegawai?.gelar_belakang ? `, ${pegawai.gelar_belakang}` : ''
      return nama.trim()
    }
  }
}
</script>