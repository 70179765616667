import checkoutSound from '@/assets/sounds/checkout.mp3'
import addItemSound from '@/assets/sounds/beep.mp3'
import removeItemSound from '@/assets/sounds/delete.mp3'
import dingDong from '@/assets/sounds/ding-dong.mp3'
import newQueue from '@/assets/sounds/new-queue.mp3'

export default function preloadAudio(listOnly = false) {
  const sounds = [
    checkoutSound,
    addItemSound,
    removeItemSound,
    dingDong,
    newQueue
  ];

  if (listOnly) {
    return sounds
  }

  const load = (source) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.src = source;
      audio.addEventListener('load', () => resolve(audio));
      audio.addEventListener('error', () => resolve(null));
    })
  }

  return Promise.all(sounds.map(load))
}