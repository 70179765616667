import { defineStore } from 'pinia'
import sls from '@/libs/secure-ls';
import { setToken } from '@/libs/axios';

const storedData = JSON.parse(sls.get('p-auth') || '{}')

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    permissions: storedData?.permissions || [],
    roles: storedData?.roles || [],
    token: storedData?.token || null,
    refreshToken: storedData?.refreshToken || null,
    userData: storedData?.userData || null,
    userId: storedData?.userId || null,
  }),
  getters: {
    entity() {
      return this.userData?.entity?.entity ?? {
        logo: '',
        name: '',
        address: '',
        phone: '',
      };
    },
  },
  actions: {
    ableTo(action, perm) {
      if(!Array.isArray(perm)) perm = [perm]
      if (action === 'read') action = 'view'
      perm = [...perm].map((v) => `${action} ${v}`)
      return this.permissions.filter(({subject}) => perm.includes(subject)).length > 0
    },
    signOut() {
      this.permissions = []
      this.roles = []
      this.token = null
      this.refreshToken = null
      this.userData = null
      this.userId = null
      this.updateStorage()
      setToken(null)
    },
    setRole(role) {
      this.roles = [...role]
      this.updateStorage()
    },
    setPermissions(permissions) {
      this.permissions = [...permissions]
      this.updateStorage()
    },
    setToken({access_token, refreshToken}) {
      this.token = access_token
      this.refreshToken = refreshToken
      this.updateStorage()
      setToken(access_token)
    },
    setUserData(userData) {
      this.userId = userData.id
      this.userData = userData
      this.updateStorage()
    },
    updateStorage() {
      sls.set('p-auth', JSON.stringify({
        permissions: this.permissions,
        roles: this.roles,
        token: this.token,
        refreshToken: this.refreshToken,
        userData: this.userData,
        userId: this.userId,
      }))
    }
  },
})
